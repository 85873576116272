export const HOME_URL = '/'
export const REWARD_URL = '/reward'
export const LOG_IN_URL = '/login'
export const FORGOT_PASSWORD_URL = '/forgot-password'
export const RESET_PASSWORD_URL = '/reset-password'
export const WELCOME_URL = '/welcome'
export const SIGNUP_URL = '/signup'
export const REDEEM_POINTS_URL = '/redeem-points'
export const REDIRECT_URL = '/redirect'
export const ACCOUNT_URL = '/account'
export const TRANSACTIONS_URL = '/transactions'
export const PAGE_NOT_FOUND_URL = '/not-found'
export const MANAGE_ACCOUNTS_URL = '/manage-accounts'
export const EXCHANGE_POINTS_URL = '/exchange-points'
export const NFTS_URL = '/nfts'
export const CASHBACK_URL = '/cashback'
export const ENGRAVING_MESSAGES_URL = '/engraving-messages'
export const BUY_POINTS_URL = '/buy-points'
export const EARN_POINTS_URL = '/earn-points'
// export const STAKING_URL = '/staking' // @TODO: Need to revert when staking ready
export const STAKING_URL = '/wallet'
export const EARN_VIA_X = '/earn-via-x'
export const UNAUTHORIZED_URLS = [
  HOME_URL,
  REWARD_URL,
  LOG_IN_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  SIGNUP_URL,
  MANAGE_ACCOUNTS_URL,
  EXCHANGE_POINTS_URL,
  NFTS_URL,
  CASHBACK_URL,
  ENGRAVING_MESSAGES_URL,
  BUY_POINTS_URL,
  EARN_POINTS_URL,
  STAKING_URL
]
