import {
  TRANSACTION_TYPE,
  BW_LABEL_TEXT,
  TRANSACTION_STATUS
} from 'src/libs/qb-brand-web-components'

import { isNftToken } from './brand.helpers'
import {
  exchangeProviderTitleForTransaction,
  formatCryptoValue,
  isCryptoBrand,
  isExchangeEventType
} from './pointsExchange.helpers'
import customConfig from 'customConfig'
import {
  LOYALTY_EVENT_TYPES,
  TRANSACTION_STATUS_LABEL
} from '../constants/transactions'
import { isExistsElementFromList } from './app/app.helpers'
import { formatTicks, toLower } from '../util/string.helpers'
import { TRIGGERED_BY_LABEL } from '../constants/cashback'

const formatTransaction = (transaction, t, { name }, authId) => {
  const {
    insertedAt,
    loyaltyEventType,
    brand,
    token,
    tokenTo,
    amountReceived,
    pointsBurned,
    user,
    showBrandName,
    effectiveAt,
    txHash,
    triggeredBy
  } = transaction

  let status = transaction.status
  let title = buildTransactionTitle(transaction, t, name)
  let heading = t(`${customConfig.eventTypeLabel}.${loyaltyEventType}`)
  let type = TRANSACTION_TYPE.REWARD
  let label = formatTicks(amountReceived)
  const isNft = isNftToken(token)
  if (
    isExistsElementFromList(
      [LOYALTY_EVENT_TYPES.POINTS_TO_REWARDS, LOYALTY_EVENT_TYPES.DEBIT_POINTS],
      loyaltyEventType
    )
  ) {
    label = formatTicks(pointsBurned)
    type = TRANSACTION_TYPE.REDEEM
  } else if (isExchangeEventType(loyaltyEventType)) {
    if (
      showBrandName &&
      token?.id &&
      tokenTo?.id &&
      token?.brandId === tokenTo?.brandId
    ) {
      heading = t(
        `${customConfig.eventTypeLabel}.${LOYALTY_EVENT_TYPES.POINTS_TO_REWARDS}`
      )
    } else if (isNft) {
      heading = t(customConfig.nftPurchaseLabel)
      title = tokenTo?.name
    }
    label = isCryptoBrand(brand?.id)
      ? formatTicks(formatCryptoValue(amountReceived || pointsBurned))
      : formatTicks(amountReceived || pointsBurned)
    if (loyaltyEventType === LOYALTY_EVENT_TYPES.REVERT_EXCHANGE) {
      type = amountReceived ? TRANSACTION_TYPE.REDEEM : TRANSACTION_TYPE.REWARD
    } else {
      type = amountReceived ? TRANSACTION_TYPE.REWARD : TRANSACTION_TYPE.REDEEM
    }
    if (status === TRANSACTION_STATUS.CANCELLED) {
      heading = t(transactionTitleLabel(isNft))
    } else if (
      effectiveAt &&
      status === TRANSACTION_STATUS.PENDING &&
      !txHash
    ) {
      status = TRANSACTION_STATUS_LABEL.FUTURE
      heading = t(transactionTitleLabel(isNft))
    }
  } else if (loyaltyEventType === LOYALTY_EVENT_TYPES.CODE_TO_NFT) {
    heading = t(customConfig.nftCreditLabel)
    title = token?.name
    label = 1
  } else if (loyaltyEventType === LOYALTY_EVENT_TYPES.NFT_PERSONALISATION) {
    heading = t('brand:nft.successful-engraving')
    title = token?.name
    label = 1
  } else if (loyaltyEventType === LOYALTY_EVENT_TYPES.CREDIT_NFT) {
    heading = t(customConfig.nftRewardLabel)
    title = token?.name
    label = 1
  } else if (loyaltyEventType === LOYALTY_EVENT_TYPES.NFT_TRANSFER) {
    heading = t(customConfig.nftTransferLabel)
    title = token?.name
    label = 1
    type =
      authId && authId === user?.authId
        ? TRANSACTION_TYPE.REDEEM
        : TRANSACTION_TYPE.REWARD
  } else if (loyaltyEventType === LOYALTY_EVENT_TYPES.NFT_SALE) {
    const isNftSeller = transaction?.isNftSeller
    if (isNftSeller) {
      const isSaleFee = transaction?.isSaleFee
      heading = t(customConfig.nftSaleLabel)
      if (showBrandName) {
        label = formatTicks(pointsBurned)
        title = token?.name
        type = TRANSACTION_TYPE.REWARD
      } else if (isSaleFee) {
        heading = t(customConfig.nftSaleFeeLabel)
        label = formatTicks(pointsBurned - amountReceived)
        title = token?.name
        type = TRANSACTION_TYPE.REDEEM
      } else {
        label = 1
        title = tokenTo?.name
        type = TRANSACTION_TYPE.REDEEM
      }
    } else {
      heading = t(customConfig.nftPurchaseLabel)
      label = showBrandName ? formatTicks(pointsBurned) : 1
      title = showBrandName ? token?.name : tokenTo?.name
      type = showBrandName ? TRANSACTION_TYPE.REDEEM : TRANSACTION_TYPE.REWARD
    }
  }

  if (
    [
      LOYALTY_EVENT_TYPES.CREDIT_NFT,
      LOYALTY_EVENT_TYPES.CODE_TO_NFT,
      LOYALTY_EVENT_TYPES.CREDIT_POINTS,
      LOYALTY_EVENT_TYPES.CODE_TO_POINTS
    ].includes(loyaltyEventType)
  ) {
    if (status === TRANSACTION_STATUS.CANCELLED) {
      heading = t(transactionTitleLabel(isNft))
    } else if (effectiveAt && !txHash) {
      status = TRANSACTION_STATUS_LABEL.FUTURE
      heading = t(transactionTitleLabel(isNft))
    }
  }

  // Handling cashback transactions title
  if (
    loyaltyEventType === LOYALTY_EVENT_TYPES.CREDIT_POINTS &&
    triggeredBy &&
    toLower(triggeredBy).includes(TRIGGERED_BY_LABEL)
  ) {
    heading = t(customConfig.cashbackFromLabel, {
      brand: triggeredBy.replace(new RegExp(TRIGGERED_BY_LABEL, 'i'), '').trim()
    })
  }

  return {
    date: t(BW_LABEL_TEXT.DATE_TIME, { date: insertedAt }),
    status,
    title,
    heading,
    type,
    label,
    isNft
  }
}

const transactionTitleLabel = (isNft = false) => {
  return `reward.future-reward-${isNft ? 'nft' : 'points'}`
}

const transactionDescLabel = (isNft = false) => {
  return `reward.future-reward-desc-${isNft ? 'nft' : 'points'}`
}

export { formatTransaction, transactionTitleLabel, transactionDescLabel }

const buildTransactionTitle = (
  { token, brand, showBrandName, loyaltyEventType, symbol },
  t,
  tokenName
) => {
  if (!showBrandName && brand?.name) {
    return exchangeProviderTitleForTransaction(t, brand, symbol)
  } else if (isExchangeEventType(loyaltyEventType)) {
    return token?.name
  }
  return tokenName
}
