import { formatCurrencyValue } from '../libs/qb-brand-web-components'
import { formatTicks } from './string.helpers'

const numberCeil = (number) => Math.ceil(number)

const floorNumber = (n) => Math.floor(n)

const formatCurrencyShort = (value) => {
  return formatTicks(formatCurrencyValue(value))
}

export { numberCeil, floorNumber, formatCurrencyShort }
