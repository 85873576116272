import { combineEpics } from 'redux-observable'

import brandConfig from 'brandConfig'

const features = brandConfig.features
const resolveEpicImports = () =>
  Promise.all([
    import('./auth'),
    import('./main'),
    import('./account'),
    import('./transactions'),
    import('./rewards'),
    import('./points'),
    import('./redeem'),
    import('./survey'),
    import('./exchange'),
    import('./nft'),
    import('./cashback'),
    features.showEngravingMessages ? import('./engravings') : null,
    features.homeBanners ? import('./banners') : null,
    features.staking ? import('./staking') : null,
    import('./wallet')
  ])

const getRootEpic = async () => {
  const epics = await resolveEpicImports()

  const extractedEpics = epics
    .filter((f) => f)
    .map((c) => c[Object.getOwnPropertyNames(c)[1]])

  return combineEpics(...extractedEpics)
}

export { getRootEpic }
