import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'
import { getApp } from './App'
import { unregister } from './registerServiceWorker'

const root = ReactDOM.createRoot(document.getElementById('root'))

getApp().then((App) => {
  root.render(<App />)
})

unregister()
