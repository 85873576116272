import {
  BW_LANGUAGE_EN,
  BW_LANGUAGE_DE,
  BW_LANGUAGE_HU
} from 'src/libs/qb-brand-web-components'

const brandConfig = {
  brandId: 'desert_greener',
  name: 'DGRX',
  faqEmail: 'info@desertgreener.io',
  privacyPolicyUrl: 'https://desertgreener.sale/documents/de/privacypolicy.pdf',
  termsUrl: 'https://desertgreener.sale/documents/de/agreement.pdf',
  theme: {
    colors: {
      main: '#061A1E',
      accent: '#619DB3'
    }
  },
  supportedLanguages: [BW_LANGUAGE_EN, BW_LANGUAGE_DE, BW_LANGUAGE_HU],
  features: {
    disableHowToEarnRewards: true,
    singleCoeStoreFront: false,
    disablePoints: true
  },
  cognito: {
    region: 'eu-central-1',
    poolId: 'eu-central-1_Ho7cqEtp5',
    clientId: '4n03f4qluq1tgrup096bhhbdvn',
    poolDomain: 'desertgreener.auth.eu-central-1.amazoncognito.com',
    redirectUri: 'https://wallet.desertgreener.io/login'
  },
  gaTrackingId: '',
  hotjarId: ''
}

export default brandConfig
