import { combineReducers } from 'redux'

import brandConfig from 'brandConfig'

const features = brandConfig.features

const resolveReducerImports = () =>
  Promise.all([
    import('./auth'),
    import('./main'),
    import('./account'),
    import('./transactions'),
    import('./rewards'),
    import('./survey'),
    import('./exchange'),
    import('./redeem'),
    import('./nft'),
    import('./cashback'),
    features.showEngravingMessages ? import('./engravings') : null,
    features.homeBanners ? import('./banners') : null,
    features.staking ? import('./staking') : null,
    import('./wallet')
  ])

const getRootReducer = async () => {
  const reducers = await resolveReducerImports()
  const extractedReducers = reducers
    .filter((f) => f)
    .reduce((rootReducer, c) => {
      const propertyKey = Object.getOwnPropertyNames(c)[1]
      rootReducer[propertyKey] = c[propertyKey]
      return rootReducer
    }, {})

  return combineReducers({
    ...extractedReducers
  })
}

export { getRootReducer }
