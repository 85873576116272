import { deepMerge } from 'src/libs/qb-brand-web-components'

import defaultConfig from '../common/customConfig'

const customConfig = {
  redeemPointsLabel: 'brand:welcome.redeem-points',
  myAccountLabel: 'brand:user.my-account',
  accountNumberLabel: 'brand:user.account-number',
  accountNumberCopiedLabel: 'brand:user.account-number-copied',
  accountNumberQrCodeLabel: 'brand:user.account-number-qr-code',
  scanQrCodeDescLabel: 'brand:user.scan-qr-code-desc',
  pointsOverviewLabel: 'brand:user.points-overview',
  redeemYourPointsLabel: 'brand:user.redeem-your-points',
  noTransactionsLabel: 'brand:user.no-transactions-yet',
  youEarnedItTitle: 'brand:user.you-earned-it',
  receivedPointsDesc: 'brand:user.received-points',
  receivedPointsButtonLabel: 'brand:common.awesome',
  incorrectRewardCodeLabel: 'brand:user.incorrect-reward-code',
  unsubscribeMessageLabel: 'brand:user.unsubscribe-text',
  receiveYourRewardLabel: 'brand:user.receive-your-reward',
  enterYourRewardCodeLabel: 'brand:user.enter-your-reward-code',
  receiveRewardLabel: 'brand:user.receive-reward',
  eventTypeLabel: 'brand:user.event-type',
  rewardCodeUsedLabel: 'brand:user.reward-code-used',
  loginPageDescLabel: 'brand:user.view-your-earned-points'
}

export default deepMerge(defaultConfig, customConfig)
